import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { $primaryWhite } from '../../../styles/colors';
import { getSetState } from '../../../utils/functions/set-creation';

export interface SetStateBoxProps {
  nextCalibrationDate: Date;
  isComplete?: boolean;
}

const Container = styled.div`
  background-color: ${({ color }) => color};
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  max-width: 150px;
  min-width: 110px;
  padding: 6px 0;
  text-align: center;
  white-space: pre-line;
`;

export const SetStateBox = (props: SetStateBoxProps) => {
  const { t } = useTranslation('gauge');
  const { status, background } = getSetState(props.isComplete, props.nextCalibrationDate, t);

  return (
    <Container color={background} data-testid="set-state-box-label-box">
      <Typo variant="subtitle2" lineHeight={1} color={$primaryWhite}>
        {status}
      </Typo>
    </Container>
  );
};
