import { Typo } from '@digital-at-vallourec/steel-design-system-react';
import { GridColDef } from '@mui/x-data-grid';

import DhlIcon from '../../../assets/icons/icon-dhl.svg';
import FedexIcon from '../../../assets/icons/icon-fedex.svg';
import { $primaryNavy } from '../../../styles/colors';
import { BookingStatus, DispatchBy } from '../../../utils';
import { stringDateComparator } from '../../../utils/functions';
import { columnRenderHeader } from '../../data-grid/utils/functions';

export const accessButtonTitleKey = 'manageShipments.accessButtons.title';
export const accessButtonSubtitleKey = 'manageShipments.accessButtons.subtitle';

export const statusFilters = [
  BookingStatus.ReturnReceived,
  BookingStatus.Delivered,
  BookingStatus.ShipmentOngoing,
  BookingStatus.ReturnOngoing,
];

export const manageShipmentsColumns: GridColDef[] = [
  {
    field: 'licensee_number',
    headerName: 'LICENSEE #',
    minWidth: 140,
  },
  {
    field: 'licensee_name',
    headerName: 'LICENSEE NAME',
    minWidth: 140,
    flex: 1,
  },
  {
    field: 'shipping_source',
    headerName: 'SOURCE',
    minWidth: 140,
    renderCell: /* istanbul ignore next */ ({ value }) => (
      <Typo
        variant="body2"
        color={$primaryNavy}
        fontWeight={500}
        textTransform="uppercase"
        className="text-ellipsis tw-inline-flex"
      >
        {value}
      </Typo>
    ),
  },
  {
    field: 'shipment_date',
    headerName: 'SHIPMENT DATE',
    minWidth: 140,
    sortComparator: stringDateComparator,
  },
  {
    field: 'carrier',
    headerName: 'CARRIER',
    minWidth: 140,
    renderCell: /* istanbul ignore next */ ({ value }) => {
      switch (value) {
        case DispatchBy.DHL:
          return <img className="tw-py-4" src={DhlIcon} alt="dhl-icon" />;
        case DispatchBy.FEDEX:
          return <img className="tw-py-4" src={FedexIcon} alt="fedex-icon" />;
        case DispatchBy.OTHER:
          return (
            <Typo
              variant="body2"
              color={$primaryNavy}
              fontWeight={500}
              textTransform="uppercase"
              className="tw-inline-flex"
            >
              Other
            </Typo>
          );
        default:
          return value;
      }
    },
  },
  {
    field: 'box_count',
    headerName: '# OF BOXES',
    minWidth: 120,
  },
  {
    field: 'tracking_number',
    headerName: 'TRACKING #',
    minWidth: 140,
    flex: 1,
  },
  {
    field: 'included_sets',
    headerName: 'INCLUDED SETS',
    minWidth: 140,
  },
  {
    field: 'address_name',
    headerName: 'ADDRESS NAME',
    minWidth: 140,
    flex: 1,
  },
];

export const showDetailShipmentColumns: GridColDef[] = [
  {
    field: 'set_id',
    headerName: 'SET ID',
    flex: 0.75,
  },
  {
    field: 'designation',
    headerName: 'DESIGNATION',
    flex: 2.5,
  },
  {
    field: 'business_number',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('BUSINESS', 'NUMBER'),
    flex: 1,
  },
  {
    field: 'purchase_order',
    renderHeader: /* istanbul ignore next */ () => columnRenderHeader('PURCHASE', 'ORDER'),
    flex: 1,
  },
  {
    field: 'gauge_weight',
    headerName: 'GAUGE WEIGHT',
    flex: 1,
  },
  {
    field: 'case_weight',
    headerName: 'CASE WEIGHT',
    flex: 1,
  },
  {
    field: 'comments',
    headerName: 'Supplementary Information',
    flex: 2,
  },
];
