import { SectionTitle, SimpleButton, Typo } from '@digital-at-vallourec/steel-design-system-react';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { isBefore } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DataGridStyledContainer,
  DataGridStyledGrid,
  GridStatus,
  UNSELECTED_ROW_CLASSNAME,
} from '../../components/data-grid/utils';
import { ContainerOutlet } from '../../components/layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, VamMoreOptions, VamPageTitle } from '../../components/shared';
import { useAppSelector } from '../../hooks';
import { useFetchGaugesBookingByStatus } from '../../hooks/useFetchGaugesBookingByStatus';
import { useSelectOrFetchPuAddress } from '../../hooks/useSelectOrFetchPuAddress';
import { ManageBookingGrid } from '../../interfaces/veg';
import { selectLicenseeName, selectLicenseeNumber } from '../../services/store/userSlice';
import { $grey20 } from '../../styles/colors';
import { BOOKING_STATUS_COLORS, BookingStatus } from '../../utils';
import { getTownById, parseToValidDate } from '../../utils/functions';
import { redirectTo } from '../../utils/functions/helpers';
import ShipIcon from './../../assets/icons/prepare-shipment.svg';
import { RETURN_BOOKINGS_SORT, returnBookingsColumns } from './utils';

export function ReturnBookings() {
  const { t } = useTranslation('gauge');
  const licenseeNumber = useAppSelector(selectLicenseeNumber);
  const { t: tReturn } = useTranslation('shipment', { keyPrefix: 'returnBookings' });
  const { pickupAddress } = useSelectOrFetchPuAddress();
  const { datagrid: bookings } = useFetchGaugesBookingByStatus(
    { all_statuses: [], current_status: BookingStatus.Delivered },
    RETURN_BOOKINGS_SORT,
    licenseeNumber,
    0
  );

  const location = useLocation();
  const navigate = useNavigate();

  const licenseeName = useAppSelector(selectLicenseeName);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [currentSelectedBookings, setCurrentSelectedBookings] = React.useState<ManageBookingGrid[]>(
    []
  );
  const [currentSelectedLocation, setCurrentSelectedLocation] = React.useState<number>(null);

  /* istanbul ignore next */
  const redirectToMakeReturn = async () => {
    redirectTo(`./make-return`, navigate, {
      state: { from: location, currentSelectedBookings },
    });
  };

  return (
    <ContainerOutlet>
      <DataGridStyledContainer>
        <VamPageTitle
          subTitle={t('tag')}
          title={tReturn('title')}
          breadcrumbRoutes={[
            {
              breadcrumb: 'Return Bookings',
              path: '#',
            },
          ]}
        />
        <ContainerCard
          width="100%"
          maxWidth="100%"
          containerCardClass="tw-pt-3"
          dataTestId="return-bookings-container-card"
          avatarHeaderNode={
            // TODO add licenseeName in store should have that #ITC3770-665
            <SectionTitle title={`${tReturn('licensee')}: #${licenseeNumber} - ${licenseeName}`} />
          }
          actionHeaderNode={
            <SimpleButton
              variant="cta"
              size="small"
              endIcon={<img src={ShipIcon} alt="shipment-icon" />}
              onClick={redirectToMakeReturn}
              data-testid="make-return-proceed-btn"
              disabled={currentSelectedBookings?.length === 0}
            >
              {tReturn('proceedToReturn')}
            </SimpleButton>
          }
        >
          <DataGridStyledGrid
            initialStateColumns={{
              columnVisibilityModel: {
                licensee_name: false,
                connection: false,
              },
            }}
            columns={returnBookingsColumns
              .map((column) => ({
                ...column,
                cellClassName: /* istanbul ignore next */ (params) =>
                  currentSelectedLocation && params.row.set_location_id !== currentSelectedLocation
                    ? UNSELECTED_ROW_CLASSNAME
                    : '',
              }))
              .concat([
                {
                  field: 'status',
                  headerName: 'STATUS',
                  cellClassName: /* istanbul ignore next */ () => '',
                  renderCell: /* istanbul ignore next */ (
                    params: GridRenderCellParams<any, BookingStatus>
                  ) => (
                    <Typo
                      variant="body2"
                      color={
                        currentSelectedLocation &&
                        currentSelectedLocation !== params.row.set_location_id
                          ? $grey20
                          : BOOKING_STATUS_COLORS[params.value]
                      }
                      fontWeight={500}
                      className="text-ellipsis tw-inline-flex"
                    >
                      {t(`manageBookingDetail.changeStatusDialog.status.${params.value}`)
                        .toString()
                        .toUpperCase()}
                    </Typo>
                  ),
                  minWidth: 120,
                  flex: 1,
                  sortable: false,
                  disableColumnMenu: true,
                },
                {
                  field: 'action',
                  align: 'right',
                  flex: 0.5,
                  cellClassName: /* istanbul ignore next */ () => '',
                  renderHeader: /* istanbul ignore next */ () => <></>,
                  renderCell: /* istanbul ignore next */ ({ row }) => {
                    return (
                      <VamMoreOptions
                        disabled={
                          currentSelectedLocation && currentSelectedLocation !== row.set_location_id
                        }
                        size="small"
                        options={[]}
                        row={row}
                      />
                    );
                  },
                  sortable: false,
                  hideable: false,
                  disableColumnMenu: true,
                },
              ])}
            rows={bookings.map((booking) => {
              const bookingEndDate = parseToValidDate(booking.end_date);

              return {
                ...booking,
                gauge_location: getTownById(pickupAddress, booking.set_location_id),
                grid_status: isBefore(bookingEndDate, new Date())
                  ? GridStatus.EXPIRE
                  : GridStatus.STANDARD,
              };
            })}
            disableColumnFilter
            showRowLeftThickBorder
            checkboxSelection
            onRowSelectionModelChange={
              /* istanbul ignore next */ (rowSelectionModel: GridRowSelectionModel) => {
                setCurrentSelectedLocation(
                  bookings?.[rowSelectionModel[0] as number]?.set_location_id
                );
                setCurrentSelectedBookings(
                  bookings.filter((booking) => rowSelectionModel.includes(booking.id))
                );
                setSelectionModel(rowSelectionModel);
              }
            }
            rowSelectionModel={selectionModel}
            keepNonExistentRowsSelected
            hideFooter
            autoHeight
            isRowSelectable={(params) =>
              !currentSelectedLocation || params.row.set_location_id === currentSelectedLocation
            }
          />
        </ContainerCard>
      </DataGridStyledContainer>
    </ContainerOutlet>
  );
}
