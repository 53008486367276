import { JSXElementConstructor, ReactElement } from 'react';

import { RentalType } from '../../../utils/constants/booking';
import styles from './RentalTypeNode.module.scss';

export function RentalTypeNode(
  value: RentalType
): ReactElement<any, string | JSXElementConstructor<any>> {
  const getFormatedType = (value: RentalType): { classStyle: string; formattedValue: string } => {
    switch (value) {
      case RentalType.Long:
        return { classStyle: styles.RentalLong, formattedValue: 'LTR' };
      case RentalType.Short:
        return { classStyle: styles.RentalShort, formattedValue: 'STR' };
    }
  };

  const { classStyle, formattedValue } = getFormatedType(value);

  return (
    <div className="tw-inline-flex tw-align-middle">
      <div className={`${styles.Rental} ${classStyle}`}>{formattedValue}</div>
    </div>
  );
}
