export const ROLES = {
  ROLE_LICENSEE_GAUGE: 'licensee-gauge',
};

export const PERMISSIONS = {
  MY_GAUGES_ALL: 'mygauges:all',
  MANAGE_LICENSEE_ALL: 'manage-licensee:all',
  MANAGE_BOOKING_ALL: 'manage-booking:all',
  CREATE_BOOKING_ALL: 'create-booking:all',
  SET_MODEL_ALL: 'set-model:all',
  SET_ALL: 'set:all',
  SHIPMENT_ALL: 'shipment:all',
  RETURN_BOOKINGS_ALL: 'return-bookings:all',
  MANAGE_USER_ALL: 'manage-user:all',
  MANAGE_MY_USERS_ALL: 'manage-my-users:all',
  INVOICES_ALL: 'invoices:all',
  ROYALTIES_JOB_ALL: 'royalties-job:all',
  PLANT_ROYALTIES_JOB_ALL: 'plant-royalties-job:all',
  DOCUMENTATION_ALL: 'documentation:all',
  DOCUMENTATION_READ: 'documentation:read',
};

export const ENTITY_PERMISSIONS = {
  ManageBooking: [PERMISSIONS.MANAGE_BOOKING_ALL],
  BookingDetails: [PERMISSIONS.MY_GAUGES_ALL],
  CreateBooking: [PERMISSIONS.CREATE_BOOKING_ALL],
  SetModel: [PERMISSIONS.SET_MODEL_ALL],
  Set: [PERMISSIONS.SET_ALL],
  MyGauges: [PERMISSIONS.MY_GAUGES_ALL],
  ManageShipments: [PERMISSIONS.SHIPMENT_ALL],
  ManageLicensee: [PERMISSIONS.MANAGE_LICENSEE_ALL],
  PrepareReturns: [PERMISSIONS.RETURN_BOOKINGS_ALL],
  ManageUser: [PERMISSIONS.MANAGE_USER_ALL],
  ManageMyUsers: [PERMISSIONS.MANAGE_MY_USERS_ALL],
  ManageInvoice: [PERMISSIONS.INVOICES_ALL],
  ManageCompanies: [PERMISSIONS.MANAGE_LICENSEE_ALL],
  CreateJob: [PERMISSIONS.ROYALTIES_JOB_ALL],
  CreatePlantJob: [PERMISSIONS.PLANT_ROYALTIES_JOB_ALL],
  ManageDocumentation: [PERMISSIONS.DOCUMENTATION_ALL],
  ManageDocumentationRead: [PERMISSIONS.DOCUMENTATION_READ],
};

// TEST FIXME ALL  https://reactrouter.com/upgrading/v6
export const LINK_PERMISSIONS = [
  {
    title: 'VAM® Easy Gauge',
    description: 'Find here all your links related to VAM® Easy Gauge',
    links: [
      {
        label: 'Gauge Booking',
        path: '/vam-easy-gauge/booking',
        permission: ENTITY_PERMISSIONS.CreateBooking,
      },
      {
        label: 'My Gauges',
        path: '/vam-easy-gauge/my-gauges',
        permission: ENTITY_PERMISSIONS.MyGauges,
      },
      {
        label: 'Return My Bookings',
        path: '/vam-easy-gauge/return-bookings',
        permission: ENTITY_PERMISSIONS.PrepareReturns,
      },
      {
        label: 'Reservation Management',
        path: '/admin/vam-easy-gauge/reservation-management',
        permission: ENTITY_PERMISSIONS.ManageBooking,
      },
      {
        label: 'Rental Data',
        path: '/admin/vam-easy-gauge/rental-data',
        permission: ENTITY_PERMISSIONS.ManageBooking,
      },
      {
        label: 'Current Rentals',
        path: '/admin/vam-easy-gauge/current-rentals',
        permission: ENTITY_PERMISSIONS.ManageBooking,
      },
      {
        label: 'Create Set Model',
        path: '/admin/vam-easy-gauge/set-model/create',
        permission: ENTITY_PERMISSIONS.SetModel,
      },
      {
        label: 'Set Models Management',
        path: '/admin/vam-easy-gauge/set-model/set-models-management',
        permission: null,
      },
      {
        label: 'Create Set',
        path: '/admin/vam-easy-gauge/set-creation/create',
        permission: ENTITY_PERMISSIONS.Set,
      },
      {
        label: 'Sets Management',
        path: '/admin/vam-easy-gauge/set-creation/sets-management',
        permission: null,
      },
      {
        label: 'Cases Listing',
        path: '/admin/vam-easy-gauge/case/cases-listing',
        permission: ENTITY_PERMISSIONS.SetModel,
      },
      {
        label: 'Depots Listing',
        path: '/admin/vam-easy-gauge/depot/depot-listing',
        permission: ENTITY_PERMISSIONS.ManageBooking,
      },
      {
        label: 'Dispatch',
        path: '/admin/vam-easy-gauge/dispatch',
        permission: null,
      },
      {
        label: 'Manage shipments',
        path: '/admin/vam-easy-gauge/manage-shipments',
        permission: ENTITY_PERMISSIONS.ManageShipments,
      },
    ],
  },
  {
    title: 'Documentation',
    description: 'Find here all your links related to Documentation',
    links: [
      {
        label: 'My Documentation',
        path: '/vam-easy-gauge/licensee-documentation',
        permission: ENTITY_PERMISSIONS.ManageDocumentationRead,
      },
      {
        label: 'Create Publication',
        path: '/admin/vam-easy-gauge/documentation/create',
        permission: ENTITY_PERMISSIONS.ManageDocumentation,
      },
      {
        label: 'Manage Publication',
        path: '/admin/vam-easy-gauge/documentation',
        permission: ENTITY_PERMISSIONS.ManageDocumentation,
      },
    ],
  },
  {
    title: 'Royalties',
    description: 'Find here all your links related to Royalties',
    links: [
      {
        label: 'Create a Job',
        path: '/vam-easy-gauge/royalties',
        permission: ENTITY_PERMISSIONS.CreateJob,
      },
      {
        label: 'Create a Job',
        path: '/vam-easy-gauge/plant-royalties',
        permission: ENTITY_PERMISSIONS.CreatePlantJob,
      },
    ],
  },
  {
    title: 'User Management',
    description: 'Find here all your links related to User Management',
    links: [
      {
        label: 'My Profile',
        path: '/vam-easy-gauge/licensee-users/manage-user',
        permission: ENTITY_PERMISSIONS.ManageMyUsers,
      },
      {
        label: 'My Users',
        path: '/vam-easy-gauge/licensee-users',
        permission: ENTITY_PERMISSIONS.ManageMyUsers,
      },
      {
        label: 'Manage Users Roles',
        path: '/vam-easy-gauge/manage-my-users',
        permission: ENTITY_PERMISSIONS.ManageUser,
      },
      {
        label: 'Licensee Listing',
        path: '/admin/vam-easy-gauge/licensee/licensee-listing',
        permission: ENTITY_PERMISSIONS.ManageLicensee,
      },
      {
        label: 'Create a Licensee',
        path: '/admin/vam-easy-gauge/licensee/create',
        permission: ENTITY_PERMISSIONS.ManageLicensee,
      },
      {
        label: 'Rights Management',
        path: '/admin/vam-easy-gauge/manage-companies',
        permission: ENTITY_PERMISSIONS.ManageCompanies,
      },
      {
        label: 'Users listing',
        path: '/admin/vam-easy-gauge/user/user-listing',
        permission: ENTITY_PERMISSIONS.ManageUser,
      },
      {
        label: 'Create User',
        path: '/admin/vam-easy-gauge/user/create',
        permission: ENTITY_PERMISSIONS.ManageUser,
      },
    ],
  },
  {
    title: 'Invoices',
    description: 'Find here all your links related to Invoices',
    links: [
      {
        label: 'Invoice Listing',
        path: '/admin/vam-easy-gauge/invoices',
        permission: ENTITY_PERMISSIONS.ManageInvoice,
      },
      {
        label: 'Manage Invoices',
        path: '/admin/vam-easy-gauge/manage-invoices',
        permission: ENTITY_PERMISSIONS.ManageInvoice,
      },
    ],
  },
];
