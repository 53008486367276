import {
  SectionTitle,
  SimpleButton,
  Tabs,
  Typo,
} from '@digital-at-vallourec/steel-design-system-react';
import { $secondaryBlue } from '@digital-at-vallourec/steel-design-system-react/dist/styles/colors';
import PrintIcon from '@mui/icons-material/Print';
import { Stack } from '@mui/material';
import { GridPaginationModel, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../hooks';
import { useFetchBookingInvoicesByStatus } from '../../hooks/useFetchBookingInvoicesByStatus';
import { useSelectOrFetchBookingInvoicesCount } from '../../hooks/useSelectOrFetchBookingInvoicesCount';
import { InvoiceGrid } from '../../interfaces/invoice';
import { InvoiceStatusDialog } from '../../pages/invoicing/InvoiceStatusDialog';
import {
  getGenerel2FileName,
  getGenerel2FileNameZip,
  getInvoiceSelectedState,
} from '../../pages/invoicing/utils';
import { exportGenerel2File, splitExportGenerel2File } from '../../services/api/invoice-api';
import { selectUser } from '../../services/store/userSlice';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGINATION_MODEL } from '../../utils';
import { INVOICE_STATUS_COLORS, InvoiceStatus } from '../../utils/constants/invoice';
import { redirectTo } from '../../utils/functions/helpers';
import { getLicenseeNumberByGridRowId } from '../booking/utils';
import { VamDataGrid } from '../data-grid/VamDataGrid';
import { columnRenderHeader } from '../data-grid/utils/functions';
import { ContainerOutlet } from '../layout/ContainerOutlet/ContainerOutlet';
import { ContainerCard, MenuOptionsProps, VamMoreOptions, VamPageTitle } from '../shared';
import { VamQuickAccessButton } from '../shared/VamQuickAccessButton/VamQuickAccessButton';
import { invoicesCols } from './utils';

export function ManageInvoice() {
  const hideColumns = {
    modification_date: false,
    creation_date: false,
    set_designation: false,
    booking_id: false,
    business_number: false,
    booking_status: false,
  };
  const { t } = useTranslation('invoice');
  const { t: tCommon } = useTranslation('common');
  const [status, setStatus] = useState<InvoiceStatus>(null);
  const [openChangeStatus, setOpenChangeStatus] = React.useState<boolean>(false);
  const [selectedOptionsRow, setSelectedOptionsRow] = React.useState(null);
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [currentSelectedInvoices, setCurrentSelectedInvoices] = React.useState<InvoiceGrid[]>([]);
  const [currentSelectedLicensee, setCurrentSelectedLicensee] = React.useState<number>(null);
  const [currentSelectedPo, setCurrentSelectedPo] = React.useState<string>(null);
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  const [sort, setSort] = React.useState<GridSortModel>([
    { field: 'modification_date', sort: 'asc' },
  ]);
  const location = useLocation();
  const navigate = useNavigate();
  const { depotId } = useAppSelector(selectUser);
  const allStatuses = [
    InvoiceStatus.L2_GENERATED,
    InvoiceStatus.READY,
    InvoiceStatus.PAID,
    InvoiceStatus.TO_BE_TREATED,
  ];

  const { statusCount } = useSelectOrFetchBookingInvoicesCount(allStatuses, depotId);
  const { datagrid: invoices } = useFetchBookingInvoicesByStatus(
    status,
    sort,
    paginationModel.page,
    depotId
  );

  /* istanbul ignore next */
  const tabs = [
    {
      label: t('manageInvoices.tabs.allInvoices'),
      onClick: () => handleTabChange(null),
      counter: statusCount['all_invoices'],
    },
    {
      label: t(`manageInvoices.tabs.${InvoiceStatus.TO_BE_TREATED}`),
      onClick: () => handleTabChange(InvoiceStatus.TO_BE_TREATED),
      counter: statusCount[InvoiceStatus.TO_BE_TREATED],
    },
    {
      label: t(`manageInvoices.tabs.${InvoiceStatus.READY}`),
      onClick: () => handleTabChange(InvoiceStatus.READY),
      counter: statusCount[InvoiceStatus.READY],
    },
    {
      label: t(`manageInvoices.tabs.${InvoiceStatus.PAID}`),
      onClick: () => handleTabChange(InvoiceStatus.PAID),
      counter: statusCount[InvoiceStatus.PAID],
    },
    {
      label: t(`manageInvoices.tabs.${InvoiceStatus.L2_GENERATED}`),
      onClick: () => handleTabChange(InvoiceStatus.L2_GENERATED),
      counter: statusCount[InvoiceStatus.L2_GENERATED],
    },
  ];

  /* istanbul ignore next */
  const handleTabChange = (status: InvoiceStatus) => {
    setPaginationModel({ ...paginationModel, page: 0 });
    setStatus(status);
  };

  /* istanbul ignore next */
  const optionsMenu: MenuOptionsProps[] = [
    {
      name: 'changeStatus',
      label: t('invoiceListing.actions.seeChangeInvoiceStatus'),
      handleItemClick: (_, __, row) => {
        setOpenChangeStatus(true);
        setSelectedOptionsRow(row);
      },
    },
    {
      name: 'seeDetails',
      label: t('invoiceListing.actions.seeDetails'),
      handleItemClick: (_, __, row) => {
        redirectToInvoiceDetails(row);
      },
    },
  ];

  /* istanbul ignore next */
  async function redirectToInvoiceDetails(row: InvoiceGrid) {
    const { invoice_id } = row;
    redirectTo(`../invoices/${invoice_id}`, navigate, {
      state: { from: location },
    });
  }

  /* istanbul ignore next */
  const consolidateGenerel2File = () => {
    const invoice_ids = currentSelectedInvoices.map((row) => row.invoice_id);

    exportGenerel2File(invoice_ids)
      .then(({ data }) => {
        const file = new Blob([data], { type: 'text/plain;charset=utf-8' });
        redirectTo('./', navigate, {
          state: { from: location },
        });
        saveAs(file, getGenerel2FileName(currentSelectedInvoices));
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  /* istanbul ignore next */
  const splitExportGenerel2 = () => {
    const invoice_ids = currentSelectedInvoices.map((row) => row.invoice_id);

    splitExportGenerel2File(invoice_ids)
      .then(({ data }) => {
        const file = new Blob([data], { type: 'application/octet-stream' });
        redirectTo('./', navigate, {
          state: { from: location },
        });
        saveAs(file, getGenerel2FileNameZip(currentSelectedInvoices));
      })
      .catch((error) => {
        enqueueSnackbar(tCommon('notification.printError'), {
          preventDuplicate: true,
          variant: 'error',
        });
        console.error(error);
      });
  };

  return (
    <ContainerOutlet>
      <InvoiceStatusDialog
        isOpen={openChangeStatus}
        setIsOpen={setOpenChangeStatus}
        rowData={selectedOptionsRow}
        onRefrechData={/* istanbul ignore next */ () => setSort([])}
      />
      <Stack direction="row" justifyContent="space-between" className="tw-mb-6">
        <VamPageTitle
          subTitle={t('manageInvoices.tag')}
          title={t('manageInvoices.title')}
          breadcrumbRoutes={[
            {
              breadcrumb: t('manageInvoices.title'),
              path: '#',
            },
          ]}
        />
        <VamQuickAccessButton
          title={t('manageInvoices.accessButtons.invoiceListing.title')}
          subTitle={t('manageInvoices.accessButtons.invoiceListing.description')}
          width={250}
          right={0}
          onClick={() =>
            redirectTo('../invoices', navigate, {
              state: { from: location },
            })
          }
          data-testid="invoice-listing-testid"
        />
      </Stack>
      <Tabs
        color="secondary"
        className="tw-ml-4"
        tabs={tabs}
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: $secondaryBlue,
          },
        }}
      />
      <ContainerCard
        width="100%"
        maxWidth="100%"
        containerCardClass="tw-pt-3"
        dataTestId="invoice-container"
        avatarHeaderNode={
          <SectionTitle
            title={t(`manageInvoices.card_${status || 'all_invoices'}.title`)}
            description={t(`manageInvoices.card_${status || 'all_invoices'}.subtitle`)}
          />
        }
        actionHeaderNode={
          [InvoiceStatus.READY, null].includes(status) ? (
            <div className="tw-flex tw-gap-5">
              <SimpleButton
                variant="cta"
                size="small"
                startIcon={<PrintIcon />}
                disabled={currentSelectedInvoices?.length < 2}
                onClick={splitExportGenerel2}
                data-testid="split-export-generel2-cta-btn"
              >
                {t('manageInvoices.splitExport')}
              </SimpleButton>
              <SimpleButton
                variant="cta"
                size="small"
                startIcon={<PrintIcon />}
                disabled={!currentSelectedInvoices?.length}
                onClick={consolidateGenerel2File}
                data-testid="consolidate-generel2-cta-btn"
              >
                {t('manageInvoices.consolidate')}
              </SimpleButton>
            </div>
          ) : null
        }
      >
        <VamDataGrid
          initialStateColumns={{
            columnVisibilityModel: {
              ...hideColumns,
            },
          }}
          columns={invoicesCols.concat([
            {
              field: 'invoice_type',
              renderHeader: () => columnRenderHeader('INVOICE', 'TYPE'),
              valueGetter: /* istanbul ignore next */ (value) =>
                t('invoiceListing.invoiceType.' + value),
              flex: 1.25,
            },
            {
              field: 'invoice_status',
              renderHeader: () => columnRenderHeader('INVOICE', 'STATUS'),
              flex: 1,
              renderCell: ({ value }: GridRenderCellParams<any, InvoiceStatus>) => (
                <Typo
                  variant="body2"
                  color={INVOICE_STATUS_COLORS[value]}
                  fontWeight={500}
                  className="text-ellipsis tw-inline-flex"
                >
                  {value ? t(`invoiceListing.invoiceStatus.${value}`) : '-'}
                </Typo>
              ),
            },
            {
              field: 'action',
              align: 'center',
              flex: 0.5,
              renderHeader: /* istanbul ignore next */ () => <></>,
              renderCell: /* istanbul ignore next */ ({ row }) => {
                const optionsFiltered = optionsMenu.map((option) => {
                  if (option.name === 'changeStatus') {
                    return {
                      ...option,
                      disabled: row.invoice_status === InvoiceStatus.TO_BE_TREATED,
                    };
                  }
                  return option;
                });
                return <VamMoreOptions options={optionsFiltered} row={row} />;
              },
              sortable: false,
              hideable: false,
              filterable: false,
              disableColumnMenu: true,
            },
          ])}
          checkboxSelection
          disableColumnFilter
          filterMode="server"
          height={500}
          keepNonExistentRowsSelected
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setSort}
          pageSize={DEFAULT_PAGE_SIZE}
          paginationMode="server"
          paginationModel={paginationModel}
          rowCount={statusCount[status || 'all_invoices'] || 0}
          rows={invoices}
          rowSelectionModel={selectionModel}
          showRowLeftThickBorder
          sortingMode="server"
          sortModel={sort}
          onRowSelectionModelChange={
            /* istanbul ignore next */ (rowSelectionModel: GridRowSelectionModel) => {
              setCurrentSelectedLicensee(
                getLicenseeNumberByGridRowId(invoices, rowSelectionModel[0] as number)
              );
              setCurrentSelectedPo(invoices[rowSelectionModel[0] as number]?.po_reference);
              setCurrentSelectedInvoices(
                invoices.filter((invoice) => rowSelectionModel.includes(invoice.id))
              );
              setSelectionModel(rowSelectionModel);
            }
          }
          isRowSelectable={
            /* istanbul ignore next */ (params) =>
              currentSelectedPo
                ? !getInvoiceSelectedState(
                    params.row,
                    currentSelectedInvoices,
                    currentSelectedLicensee,
                    currentSelectedPo
                  )
                : params.row.invoice_status === InvoiceStatus.READY &&
                  params.row.po_reference?.length
          }
        />
      </ContainerCard>
    </ContainerOutlet>
  );
}
