import { Checkbox } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';

import { SetGaugeModel, SetGaugeModelUI } from '../../interfaces/set-creation';
import { GridStatus } from '../data-grid/utils';

export enum GaugeError {
  REQUIRED_SERIAL_NUMBER = 'requiredSerialNumber',
  DUPLICATED_SERIAL_NUMBER = 'duplicatedSerialNumber',
  EXISTS = 'exists',
  LENGTH = 'length',
}

export const setContentColumns = (with_damage_column: boolean): GridColDef[] => [
  {
    field: 'designation_vam',
    headerName: 'vam® services designation',
    flex: 2,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'drawing_number',
    headerName: 'drawing',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'reference_number',
    headerName: 'ref. N°',
    minWidth: 120,
    flex: 1,
  },
  {
    field: 'is_present',
    headerName: 'present',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <Checkbox readOnly value={value || undefined} checked={value} />;
    },
    flex: 1,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'is_gauge_required',
    headerName: 'required',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <span>{value ? 'Yes' : 'No'}</span>;
    },
    flex: 1,
    type: 'boolean',
  },
  {
    field: 'serial_number',
    headerName: 'serial n°',
    flex: 1,
    editable: true,
    valueFormatter: /* istanbul ignore next */ (value: string) => value && value.toUpperCase(),
  },
  with_damage_column && {
    field: 'is_damaged',
    headerName: 'is damaged',
    /* istanbul ignore next */
    renderCell({ value }) {
      return <Checkbox readOnly value={value || undefined} checked={value} />;
    },
    flex: 1,
    editable: true,
    type: 'boolean',
  },
  {
    field: 'next_calibration_date',
    headerName: 'next calibration date',
    type: 'date',
    flex: 2,
    width: 280,
    editable: true,
    valueFormatter: /* istanbul ignore next */ ({ value }) => value && format(value, 'dd/MM/yyyy'),
  },
];

export function updateGauges(
  gaugeModels: SetGaugeModelUI[],
  isUpdate?: boolean
): SetGaugeModelUI[] {
  // TODO Function Cognitive Complexity. need a refactoring
  return gaugeModels.map((gaugeModel) => {
    let error = gaugeModel.error;
    // If gauge is present, serial number must be provided, however if it's required or not.
    if (gaugeModel.is_present && !gaugeModel.serial_number && !isUpdate) {
      error = GaugeError.REQUIRED_SERIAL_NUMBER;
    } else {
      // Gauge is not missing anymore, remove the error
      if (error === GaugeError.REQUIRED_SERIAL_NUMBER) {
        error = null;
      }
    }

    // Serial number should not exceed 50 chars
    if (gaugeModel.serial_number?.length > 50 && error !== GaugeError.REQUIRED_SERIAL_NUMBER) {
      error = GaugeError.LENGTH;
    } else {
      // Serial number is not exceeding 50 chars anymore
      if (error === GaugeError.LENGTH) {
        error = null;
      }
    }

    // Serial number should be unique in the grid
    if (
      gaugeModels.find(
        (gauge) =>
          gauge.id !== gaugeModel.id &&
          gauge.serial_number?.toUpperCase() === gaugeModel.serial_number?.toUpperCase() &&
          gauge.drawing_number?.toUpperCase() === gaugeModel.drawing_number?.toUpperCase() &&
          gauge.reference_number?.toUpperCase() === gaugeModel.reference_number?.toUpperCase()
      ) &&
      error !== GaugeError.REQUIRED_SERIAL_NUMBER &&
      error !== GaugeError.LENGTH
    ) {
      error = GaugeError.DUPLICATED_SERIAL_NUMBER;
    } else {
      // Serial number is not duplicated anymore, remove the error
      if (error === GaugeError.DUPLICATED_SERIAL_NUMBER) {
        error = null;
      }
    }

    return { ...gaugeModel, grid_status: error ? GridStatus.KO : GridStatus.OK, error };
  });
}

export function isGaugeModified(gauges: SetGaugeModelUI[], row: SetGaugeModel): boolean {
  return (
    // Same serial_number used but the row which had the serial_number changed
    !!gauges.find(
      (item) =>
        item.initialSerialNumber === row.serial_number &&
        item.initialSerialNumber !== item.serial_number
    ) || // Same serial_number but on the same row
    !!gauges.find(
      (item) =>
        item.initialSerialNumber === row.serial_number &&
        item.initialSerialNumber === item.serial_number &&
        item.id === row.id
    )
  );
}
